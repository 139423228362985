@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  background: #fff;
}

/* NAVIGATION */
[aria-current] {
  @apply font-bold text-black;
}

.nav-link {
  height: 64px;
}

.nav-link:hover:not([aria-current]) {
  @apply text-gray-800;
}

/* HEADER */
#header {
  height: 160px;
  transition: 0.2s;
}

/* SCROLL */
.nav-scroll {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.title-scroll {
  display: none;
  transition: 0.4s;
  transform: translate3d(0, 0, 0);
}

#scroll-title {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bg-photo-1,
.bg-photo-1 > img {
  width: 350px !important;
  height: 500px !important;
}

.bg-photo-1 > img {
  margin-top: -100px;
  margin-left: 175px;
}

.bg-photo-2,
.bg-photo-2 > img {
  width: 350px !important;
  height: 450px !important;
}

.bg-photo-3,
.bg-photo-3 > img {
  width: 600px !important;
  height: 350px !important;
}

.bg-photo-3 > img {
  margin-top: 200px;
}

/* FOOTER */

.footer-label {
  font-size: 13px;
}

/* MODAL */

#modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.85);
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  width: 90%;
}

.project-img {
  transition: 0.3s;
}

/* Images */
.enlarge-img {
  transition: 0.3s;
}

.enlarge-img:hover {
  filter: brightness(85%);
  transform: scale(1.03);
  transition: transform 0.3s, filter 0.3s;
}

.anchor {
  display: none;
  top: -200px;
}

/* Slideshow */
.jumbo-container {
  width: 100%;
  height: calc(100vh - 160px - 20px);
  position: relative;
  margin: auto;
}

.jumbo-slide {
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s linear;
}

.jumbo-slide-1 {
  display: inline-flex;
  justify-content: center;
}

.jumbo-container > .jumbo-slide:first-child {
  opacity: 1;
}

.jumbo-slide img {
  width: 100% !important;
  object-fit: cover;
}

.jumbo-text {
  position: absolute;
  top: 7%;
  user-select: none;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#circle {
  width: 180px;
  height: 180px;
  top: 64px;
  right: 128px;
  user-select: none;
  cursor: pointer;
  background-color: #f1e8df;
}

.dot-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -30px;
  display: flex;
  justify-content: center;
}

.dot {
  /* cursor: pointer; */
  height: 12px;
  width: 12px;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #f1e8df;
}

/* Fading animation
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 0.5s;
  animation-name: fade;
  animation-duration: 0.5s;
} */

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  29% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.jumbo-slide-3 {
  animation-delay: 14s;
}
.jumbo-slide-2 {
  animation-delay: 7s;
}
.jumbo-slide-1 {
  animation-delay: 0;
}

/* @-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
} */

@media only screen and (max-width: 1024px) {
  #header {
    height: 70px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .jumbo-container {
    height: calc(100vh - 90px);
  }

  .jumbo-slide {
    height: 100%;
  }

  .jumbo-slide img {
    height: 100%;
    max-width: none;
  }

  .modal-content {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .over-ons-section {
    justify-content: center !important;
  }
  .over-ons-section > img {
    width: 150px !important;
  }
}
